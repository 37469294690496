/* arrow start */
@keyframes jumpInfinite {
  0% {
    bottom: 50px;
  }

  50% {
    bottom: 10px;
  }

  100% {
    bottom: 50px;
  }
}

.down-arrow {
  position: absolute;
  bottom: 120px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.down-arrow:hover {
  cursor: pointer;
}

.down-arrow {
  animation: jumpInfinite 2s infinite;
}

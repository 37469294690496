.QDA.XbT.XiG.fBv.ujU.zI7.iyn.Hsu {
  padding-top: 0;
}

.auth-header{
  font-size: 28px;
  font-weight: 500;
  padding-left: 24px;
  font-family: 'Ubuntu', sans-serif;
}

@media screen and (max-width: 948px) {
  .auth-header{
    font-size: 18px;
    font-weight: 600;
  }
}
.scrollareaViewport{
    padding-right: 40px!important;
    padding-left: 40px!important;
    padding-bottom: 140px!important;
}

@media screen and (max-width: 768px) {
    .scrollareaViewport{
        padding-right: 20px!important;
        padding-left: 20px!important;
    }
}
/* .password-reset-confirm-password-section .xuA{
    width: 100%;
} */

#reset-pw-new-password-confirm-field{
    width: 100%;
}

#reset-pw-new-password-field, #reset-pw-new-password-confirm-field{
    font-family: 'Ubuntu', sans-serif!important;
}

.password-reset-confirm-password-section > div > div {
    width: 100%;
}

.password-reset-confirm-password-section{
    width: 100%;
}

#password-reset-info-section div{
    font-size: 15px!important;
}
.auth-form-modal-wrapper{
  padding: 10px;
}

.auth-form-outside-modal-wrapper{
  padding: 2vw;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 1);
}

@media screen and (max-width: 768px) {
  .auth-form-outside-modal-wrapper{
    padding: 5vw;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(min(100%, 500px));
  gap: 15px;
  background-color: white;
  border-radius: 20px;
}

.username-and-pw {
  align-items: center;
  gap: 5px;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  display: flex;
}

.continue-with-google-wrapper {
  margin-left: auto;
  color: #3c4043;
  margin-right: auto;
  font-size: 16px;
  font-family: "Google Sans", arial, sans-serif;
  font-weight: var(--font-weight-semibold);
}

.continue-with-google-button {
  display: flex;
  align-items: center;
  padding: 10;
  background-color: white;
  border: 2px solid #dadce0;
  border-radius: 24px;
}

.continue-with-google-button:hover {
  border: 2px solid rgb(224, 245, 251);
  background-color: rgb(240, 250, 253);
  cursor: pointer;
}

.custom-link {
  font-weight: 500;
  color: black;
  text-decoration: underline;
}

.custom-link:hover {
  cursor: pointer;
}

input:-webkit-autofill::first-line {
  font-size: 19px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.form-label {
  font-size: 13px;
  padding-left: 8px;
  font-weight: 400;
  padding-bottom: 8px;
  color: black;
}

.form-input {
  width: 100%;
  border-radius: 16px;
  padding-left: 20px;
  font-size: 19px;
  display: flex;
  align-items: center;
  border: 3px solid var(--color-border-container);
  vertical-align: sub;
}

.form-wrapper input:focus {
  border: 3px solid rgb(0 132 255/0.5);
  /* outline: 3px solid rgb(0 132 255/.5); */
  outline: 1px solid rgb(0 132 255/0.5);
}

.error-message {
  font-size: 12px;
  color: red;
  padding-left: 8px;
}

.bottom-text-wrapper {
  width: 70%;
}

@media screen and (max-width: 546px) {
  .bottom-text-wrapper {
    width: 100%;
  }
}

.auth-home{
  font-family: 'Ubuntu', sans-serif!important;
}
.wrapper-height-and-width{
    height: 300px!important;
    width: 90%!important;
    box-shadow: 1px 1px 6px 2px #9c9c9c;
}

@media screen and (max-width: 1530px) {
    .wrapper-height-and-width{
        height: 400px;
        width: 100%;
    }    
}

#bar-chart{
    border-radius: 8px;
    background-color: black!important;
}
.rdp-page-wrapper {
  height: calc(100dvh - var(--top-navbar-height));
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--top-navbar-height);
  overflow-x: hidden;
  background-color: #000000;
  overflow: hidden;
  background-image: url(https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/399d77cd-5976-43f6-b647-8ab7748d4200/authblur);
  background-size: 10%;
  background-repeat: no-repeat;
  background-position: center;
}

#display {
  opacity: 1;
  z-index: 2000;
  border-radius: 8px;
}

.rdp-page-wrapper > div > div > div > div > div > canvas{
  z-index: 20!important;
}

.bottom-text {
  color: white;
  position: absolute;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
}

.bottom-text:hover {
  color: rgb(32, 0, 0);
  cursor: pointer;
  background-color: rgb(0, 0, 0);
}

.m-396ce5cb[data-selected]{
    background-color: black!important;
    color: white!important;
}

.m-396ce5cb[data-in-range]{
    background-color: rgb(204, 182, 182);
    color: rgb(4, 4, 4);
}

.modal-modification .Jea.XiG.jzS.ujU.zI7.iyn.Hsu {
    width:90vw!important;
}
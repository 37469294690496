.neural-logic-logo {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
  hyphens: none;
}

.neural-logic-logo:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .neural-logic-logo {
    font-weight: 700;
    font-size: 24px;
  }
}

.hidden-mobile {
  display: flex;
}

@media screen and (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}

.drawer-text-link {
  font-size: 26px;
  font-weight: 500;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.drawer-text-link:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
  cursor: pointer;
}

.drawer-text-sublink {
  font-size: 20px;
  font-weight: 500;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  width: 100%;
}

.drawer-text-sublink:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
  cursor: pointer;
}

.top-drawer{
  padding-left: 12vw;
  padding-right: 12vw;
}

@media screen and (max-width:  948px) {
  .top-drawer {
    padding-left: 3vw;
    padding-right: 3vw;
  }
}

.gap-6px{
  gap: 12px;
}
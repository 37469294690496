.gpuDisplayWrapper {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gpuDisplayWrapper:hover {
  cursor: pointer;
}

.shadow-on-hover {
  background-color: white;
}

.shadow-on-hover:hover {
  background-color: rgb(232, 232, 232);
}

@keyframes scalerY {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}

.make-bigger-animation {
  animation: scalerY 100ms 1;
}

.dont-make-bigger {
  animation: none;
}

.gpu-details-subheader {
  display: flex;
  font-weight: 400;
  font-size: 12px;
}

.gpu-details-price {
  display: flex;
  flex-direction: column;
  color: green;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .gpu-details-subheader {
    font-size: 10px;
    /* font-weight: 500; */
  }

  .font-10-small-screen {
    font-size: 10px !important;
  }
}

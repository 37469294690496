.viewer {
  width: 100dvw;
  height: 100dvh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

@supports (-moz-appearance: none) {
  .viewer {
    scroll-snap-type: none;
  }
}

.slide {
  width: 100dvw;
  height: 100dvh;
  overflow: scroll;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.slide:nth-child(1) {
  background-color: #004653;
  color: #ffe4c1;
  position: relative;
  scroll-snap-stop: always;
}

.slide:nth-child(2) {
  background-color: #000000;
  color: #ffffff;
  position: relative;
  scroll-snap-stop: always;
}

.slide:nth-child(3) {
  background-color: #f9f9f9;
  color: #000000;
  position: relative;
  scroll-snap-stop: always;
}

.slide:nth-child(4) {
  background-color: #000000;
  position: relative;
  scroll-snap-stop: always;
}

.homepage-main-wrapper {
  position: relative;
  padding: 0 12vw;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 948px) {
  .homepage-main-wrapper {
    padding: 0 5vw;
    flex-direction: column;
  }
}

.bigger-container {
  flex: 0.9;
  width: 100%;
  height: 100%;
  max-height: calc(95dvh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.smaller-container {
  flex: 0.6;
  width: 100%;
  height: 100%;
  max-height: calc(95dvh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.nav-inner {
  padding-left: 12vw;
  padding-right: 12vw;
}

@media screen and (max-width: 948px) {
  .nav-inner {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.cheap-gpu-img {
  width: 90%;
}

@media screen and (max-width: 948px) {
  .cheap-gpu-img {
    width: 60%;
  }

  .smaller-container {
    flex: 0.3;
    overflow: visible;
  }

  .bigger-container {
    overflow: visible;
  }
}

@media screen and (max-width: 768px) {
  .cheap-gpu-img {
    width: 70%;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.browser-access-img {
  width: 80%;
  border-radius: 8px 4px 4px 8px;
}

.browser-access-flex-reverse {
}
.runtime-collection-wrapper {
}

@media screen and (max-width: 948px) {
  .browser-access-flex-reverse {
    flex-direction: column-reverse;
    gap: 32px;
  }

  .browser-access-img {
    width: 80%;
    border-radius: 8px 4px 4px 8px;
  }

  .runtime-collection-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .browser-access-img {
    width: 90%;
    border-radius: 8px 4px 4px 8px;
  }
}

.push-up-lg {
  padding-bottom: 80px;
}

.headerConsole {
  height: rem(56px);
  background-color: white;
  display: flex;
  width: 100%;
  box-shadow: var(--elevation-raised-top);
  top: 0px;
}

.inner {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: transparent;
  width: 100%;
  align-items: center;

}

@media screen and (max-width:768) {
  .inner{
    height: 56px;
  }
}

.link {
  display: block;
  line-height: 1;
  padding: rem(8px) rem(12px);
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif !important;
}

.link:hover {
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-6)
  );
  cursor: pointer;
}

.linkLabel {
  margin-right: rem(5px);
}

@media screen and (min-width: 768px) {
  .hiddenDesktop {
    display: none;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;1,300&family=Urbanist:wght@300;400;500;600&display=swap');

iframe#webpack-dev-server-client-overlay{display:none!important}

body,
html {
  min-height: 100%;
  height: auto !important;
}

:root {
  --mantine-color-placeholder: #616161 !important;
  --notification-error-color: red;
  --side-navbar-width : 270px;
  --side-navbar-width-sm : 80px;
  --side-navbar-padding-sm: 80px;
  --top-navbar-height: 60px;
  --font-size-100: 13px;
}

#root {
  position: relative;
  height: 100%;
}

.big-header {
  font-size: 48px;
  font-weight: bold;
  text-align: center;
}

.mid-header {
  font-size: 24px;
  text-align: center;
}

.header-72 {
  font-size: 72px;
  font-weight: bold;
  text-align: center;
  padding-right: 2rem;
  padding-left: 2rem;
}

.header-36 {
  font-size: 36px;
  text-align: center;
  padding-right: 2rem;
  padding-left: 2rem;
}

@media screen and (max-width: 1200px) {
  .big-header {
    font-size: 36px;
  }
}

@media screen and (max-width: 948px) {
  .big-header {
    font-size: 30px;
  }

  .header-72 {
    font-size: 36px;
  }

  .header-36 {
    font-size: 20px;
  }

  .mid-header {
    font-size: 18px;
  }
}

.mantine-Notification-title {
  font-size: var(--font-size-300);
  font-family: var(--font-family-default-latin);
}

.mantine-Notification-description {
  font-size: var(--font-size-200);
  font-family: var(--font-family-default-latin);
}

.mantine-Menu-dropdown {
  border-radius: 16px;
  padding: 8px;
  box-shadow: var(--elevation-floating);
  margin-top: 8px;
}

.mantine-Menu-item {
  padding-right: 30px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.mantine-Menu-item:hover {
  background-color: #e9e9e9;
  border-radius: 8px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.on-hover-pointer:hover {
  cursor: pointer !important;
}

.m-f0824112{
  background: rgb(0, 0, 0)!important;
  font-size: 16px!important;
}

.m-f0824112[data-active]{
  background: rgb(47, 47, 47)!important;
  border-radius: 2px;
}

* {
  font-family: 'Urbanist', sans-serif;
}

.mantine-Select-input{
  font-family: 'Urbanist', sans-serif!important;
}

.iFc{
  font-family: 'Urbanist', sans-serif!important;
}

.m-92253aa5{
  font-size: 14px!important;
}

/* .dR0{
  font-size: 14px;
  font-weight:450;
} */

th {
  font-weight: 550;
}

.fs-14{
  font-size: 14px;
}

.fs-12{
  font-size: 12px;
}

.fs-16{
  font-size: 16px;
}

.fs-18{
  font-size: 18px;
}

.info-section {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  color: rgb(27, 27, 27);
}

.support-form-wrapper .O2T{
  color: rgb(19, 19, 19);
  font-size: 13px;
  font-weight: 400;
}

.O2T{
  user-select: none;
}

.half-and-full{
  display: flex;
  flex-direction: row; 
  gap: 64px;
}

@media screen and (max-width: 1200px) {
  .half-and-full{
    flex-direction: column; 
    gap: 48px;
  }
}

.centered-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}

.mantine-datatable{
  min-height: 120px;
}

input .O2T{
  color: black!important;
}

td{
  font-size: 16px
}

.must-underline{
  text-decoration: underline!important;
}

.console-home-wrapper {
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 80px;
  scroll-margin-top: 70px;
  background-color: white;
  min-height: 100dvh;
}

@media screen and (min-width: 1440px) {
  .console-home-wrapper {
    padding-left: 18vw;
    padding-right: 18vw;
  }
}

@media (min-width: 768px) and (max-width: 948px) {
  .console-home-wrapper {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

@media screen and (max-width: 768px) {
  .console-home-wrapper {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.a {
  background-color: #fafafa;
}

.segments-container {
  padding-top: 10px;
}

.ho- {
  border-radius: 4px !important;
}

.on-hover-slightly-darker:hover {
  background-color: rgb(244, 244, 244);
}

.on-hover-slightly-darker:active {
  transform: translateX(0.5px);
}

.logs-wrapper{
  width: 60%;
}

@media screen and (max-width: 1200px) {
  .logs-wrapper{
      width: 80%;
  }
}

@media screen and (max-width: 948px) {
  .logs-wrapper{
      width: 100%;
  }
}

.power-btns:hover {
  transform: scale(0.98);
}

.ssh-terminal {
  font-size: 16px;
  font-weight: 500;
  background-color: #1d1d1d;
  border-radius: 4px;
  color: #e5f3ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
}

.connection-item-width {
  width: 50%;
  max-width: 550px;
  min-width: 375px;
}

@media screen and (max-width: 1200px) {
  .connection-item-width {
    width: 80%;
    max-width: 550px;
    min-width: 375px;
  }
}

.rdp-title {
  font-size: 14px;
  font-weight: 600;
}

.name-display {
  border: none !important;
  outline: none !important;
  font-size: 26px !important;
  font-weight: 400;
}

.name-display:disabled {
  background-color: white !important;
  color: black !important;
  opacity: 1.2 !important;
}

.name-input-wrapper {
  background-color: transparent !important;
  color: black !important;
}

.mono-font-family {
  font-family: "Azeret Mono", monospace !important;
  font-size: 16px !important;
}

.machine-detail-text-title {
  color: #585C67;
  font-size: 14px;
}

.machine-detail-text-content {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.connection-wrappers {
  display: flex;
  flex-direction: row;
  gap: 16px;
  row-gap: 24px;
  margin-top: 16px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1250px) {
  .connection-wrappers {
    flex-direction: column;
  }
}

.connection-wrappers-individual {
  width: 49%;
}

@media screen and (max-width: 1250px) {
  .connection-wrappers-individual {
    width: 100%;
  }
}

.connection-wrappers-sm {
  max-width: 660px;
}

.divider-margin {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

.machine-detail-page-section-wrapper {
  width: 100%;
}

@media screen and (max-width: 1250px) {
  .machine-detail-page-section-wrapper {
    width: 100%;
  }
}

.padding-5vw-and-2vw{
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: calc(max(2.5vh, 1vw))
}

@media screen and (max-width: 480px) {
  .padding-5vw-and-2vw{
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.padding-5vw-and-2vw-no-pt{
  padding-left: 5vw;
  padding-right: 5vw;
}

.bottom-banner-inside-console{
  position: fixed;
  background-color: #FDC900;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  z-index: 8;
}

@media screen and (max-width: 768px) {
  .bottom-banner-inside-console{
    flex-direction: column!important;
  }
}

.bottom-banner-console{
  padding-top: 13px;
  padding-bottom: 13px;
  width: calc(100vw - var(--side-navbar-width));
}

@media screen and (max-width: 948px) {
  .bottom-banner-console{
    width: calc(100vw - var(--side-navbar-width-sm));
  }
}

.bottom-banner-homepage{
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  left: 0;
}

@media screen and (max-width: 948px) {
  .bottom-banner-homepage {
    display: none!important;
  }
}

/* style={{ position: "fixed", bottom: 0, width: "100%", height: "60px", backgroundColor: "#FDC900" }} */

@media screen and (max-width: 948px) {
  .bottom-banner-w{
    /* padding-left: var(--side-navbar-width-sm); */
    width: calc(100vw - var(--side-navbar-width-sm));
  }
}

#rdp-pw-id{
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 8px;
  font-family: 'Ubuntu', sans-serif!important;
  font-weight: 500;
}

#rdp-user-display{
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 8px;
  font-family: 'Ubuntu', sans-serif!important;
  color: black;
  min-width: 90px;
}

#ssh-from-browser-pw-id{
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 8px;
  font-family: 'Ubuntu', sans-serif!important;
  font-weight: 500;
  user-select: none;
}

.light-raised-shadow{
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.m-d-p-section-wrapper-half{
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

.m-d-p-section-wrapper{
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.between-flex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.settings-page{
  padding-left: 15vw;
  padding-right: 15vw;
}

#header-example{
  border-radius: 8px;
}

.child-that-disappears{
  display: none!important;
}

.parent-that-hovers:hover .child-that-disappears{
  display: block!important;
}

#machine-name-id{
  border-radius: 8px;
}

.mantine-dropdown{
  border: 2px solid rgb(205, 205, 205)!important;
  border-radius: 4px;
  font-family: 'Ubuntu', sans-serif!important;
}

.mantine-dropdown:focus{
  box-shadow: 0 0 0 2px rgb(0 132 255/.5);
  /* border: none; */
}

.mantine-dropdown-root{
  border: none;
}

.thick-border{
  box-shadow: 0 0 0 2px rgb(0 132 255/.5)!important;
  border: 2px solid rgb(205, 205, 205)!important;
}

.m-38a85659{
  border: 2px solid rgb(205, 205, 205);
}

#support-form-wrapper .m-8fb7ebe7 {
  font-weight: 500;
}

button[name=settings-submit-btn]{
  background-color: #006061;
  border-radius: 8px;
}

button[name=settings-submit-btn] > div{
  color: white;
}

button[name=settings-submit-btn] > div > div{
  color: #ffffff!important;
  font-weight: 500!important;
  font-size: 15px!important;
}

button[name=increase-size-submit-btn]{
  background-color: #006061;
  border-radius: 8px;
}

button[name=increase-size-submit-btn] > div{
  color: white;
}

button[name=increase-size-submit-btn] > div > div{
  color: #ffffff!important;
  font-weight: 500!important;
  font-size: 15px!important;
}

.ho- {
  border-radius: 4px !important;
}

.on-hover-slightly-darker:hover {
  background-color: rgb(244, 244, 244);
}

.on-hover-slightly-darker:active {
  transform: translateX(0.5px);
}

.machine-list-title-l2 {
  font-size: 14px !important;
  font-weight: 300 !important;
}

.machine-list-title-l3 {
  font-size: 12px !important;
}

.custom-accordion-label {
  position: relative;
}

.custom-accordion-label:hover {
  background-color: rgb(233, 234, 236);
  cursor: pointer;
}

.create-machine-form-wrapper {
  width: 70%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  position: relative;
  padding-bottom: 100px;
}

.create-machine-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.runtime-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .create-machine-form-wrapper {
    width: 90%;
    background-color: transparent;
  }
}

@media screen and (min-width: 1440px) {
  .create-machine-form-wrapper {
    width: 50%;
    background-color: transparent;
  }
}

@media (min-width: 768px) and (max-width: 948px) {
  .create-machine-form-wrapper {
    width: 70%;
    background-color: transparent;
  }
}

.select-component-border{
  border: 2px solid var(--color-border-container);
  border-radius: 16px;
}

.console-input-border-box-shadow-and-placeholder {
  border: 1px solid #d2d2d7 !important;
}

.console-input-border-box-shadow-and-placeholder:hover {
  border: 1.5px solid white !important;
  box-shadow: 1px 1px 1px black !important;
}

.console-input-border-box-shadow-and-placeholder:focus {
  border: 1.5px solid white !important;
  box-shadow: 1px 1px 1px black !important;
}

.console-input-border-box-shadow-and-placeholder::placeholder {
  /* color: black; */
  /* opacity: 0.4; */
  font-weight: 400;
  color: rgb(9, 9, 9);
}

.console-input-border-box-shadow-and-placeholder {
  font-weight: 400;
}

.os-image-display {
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-auto-rows: 1fr;
  width: 90%;
}

.region-button-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .region-button-wrapper {
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .os-image-display {
    width: 100%;
  }
}

.grid-individual-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
  position: relative;
}

.grid-individual-container-text {
  font-weight: 500;
  display: flex;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.grid-individual-container:hover {
  cursor: pointer;
}

.text-tag {
  font-weight: 400;
  display: grid;
}

@media screen and (max-width: 768px) {
  .os-image-display {
    grid-gap: 10px;
  }

  .grid-individual-container-text {
    font-size: 12px;
  }

  .text-tag {
    font-weight: 400;
    font-size: 10px;
  }
}

@keyframes makeBigger {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.97);
  }

  100% {
    transform: scale(1);
  }
}

.animate {
  animation: makeBigger 150ms 1;
}

@keyframes moveDownAnimation {
  0% {
    bottom: -12px;
  }

  50% {
    bottom: -18px;
  }

  100% {
    bottom: -12px;
  }
}

.move-down {
  animation: moveDownAnimation 1.5s infinite;
}

.compute {
}

.compute:hover {
  cursor: pointer;
}

.ai-text {
  height: 50px;
  width: 50px;
  border-radius: 40%;
  background-color: transparent;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: "Ubuntu", sans-serif !important;
}

.create-machine-form-wrapper > * {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif !important;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.region-button {
  font-weight: 400 !important;
  border: 1px solid #d2d2d7 !important;
}

.region-button:hover {
  cursor: pointer;
}

.floating-check-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: white;
}

.mantine-Accordion-chevron {
  width: 24px !important;
}

.pricing-summary-wrapper {
  background-color: white;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  border: 1px solid #d2d2d7 !important;
}

.custom-template-icon{
  position: absolute;
  top: 5px;
  left: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.mantine-PasswordInput-innerInput{
  font-family: 'Ubuntu', sans-serif!important;
}

.machine-detail-page-wrapper {
  padding-top: 65px;
  padding-left: var(--side-navbar-width);
  overflow: hidden;
}

@media screen and (max-width: 948px) {
  .machine-detail-page-wrapper {
    padding-left: var(--side-navbar-width-sm);
  }
}

.display-machine-list-wrapper {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  height: 100%;
  padding-bottom: 30px;
}

@media screen and (max-width: 1100px) and (min-width: 948px) {
  .display-machine-list-wrapper {
    width: 80%;
    background-color: transparent;
  }
}

@media screen and (max-width: 768px) {
  .display-machine-list-wrapper {
    width: 100%;
    background-color: transparent;
  }
}

@media screen and (min-width: 1440px) {
  .display-machine-list-wrapper {
    width: 70%;
    background-color: transparent;
  }
}

@media (min-width: 768px) and (max-width: 948px) {
  .display-machine-list-wrapper {
    width: 70%;
    background-color: transparent;
  }
}

.individual-machine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  background-color: #ffffff;
  box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.6);
  position: relative;
  flex-basis: 30%;
}

.individual-machine:hover {
  cursor: pointer;
}

.machine-list-display {
  background-color: transparent;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  grid-auto-rows: 1fr;
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .machine-list-display {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

.machine-list-title-l1 {
  font-size: 18px;
  font-weight: 600;
}

.machine-list-title-l2 {
  font-size: 14px;
}

.machine-list-title-l3 {
  font-size: 12px;
}

@keyframes blink-slower {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 75%;
  }
}

.blink-animation-slower {
  animation: 2s blink-slower infinite;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.gap-8 {
  gap: 8px;
}

.gap-2 {
  gap: 2px;
}

.connect-btn-color {
  /* background-image: linear-gradient(90deg, #667eea, #8E37D7)!important; */
  /* border: 1px solid black!important; */
  background-image: linear-gradient(to right, orange, indigo) !important;
  border: none !important;
  font-weight: 700 !important;
}

.connect-btn-color:hover {
  background-image: linear-gradient(to right, #5a9, #39424e) !important;
  /* color: black; */
}

.create-new-vm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  flex-basis: 30%;
  box-sizing: border-box;
}

.create-new-vm .mantine-Button-section {
  margin: 2px;
}

@keyframes saltShaker {
  0% {
    transform: rotate(0deg) translateY(0);
  }
  25% {
    transform: rotate(-10deg) translateY(-3px);
  }
  50% {
    transform: rotate(10deg) translateY(3px);
  }
  75% {
    transform: rotate(-10deg) translateY(-3px);
  }
  100% {
    transform: rotate(0deg) translateY(0);
  }
}

.shake-animation {
  animation: saltShaker 2s 10;
}

@keyframes grow {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.2);
  }
}

.bottom-notch:hover {
  animation: grow 1s infinite;
  cursor: pointer;
}

.machine-list-display .ai-text {
  background-color: black;
  color: white;
  border-radius: 50%;
}

.machine-individual-content-wrapper {
  background-color: #fefefe;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 30px;
  padding-right: 40px;
  padding-left: 40px;
}

.raised {
  box-shadow: 0px 0px 2px 0px rgb(167, 167, 167);
}

.divider-parent:hover .invisible-child {
  opacity: 0;
  visibility: hidden;
}

.light-raised {
  box-shadow: 0px 1px 3px 0px rgba(4, 4, 4, 0.7);
}

.press-animation:active {
  transform: translateY(2px) translateX(1px);
  background-color: rgb(242, 242, 242);
}

.press-animation-harder:active {
  transform: translateY(4px) translateX(4px);
  background-color: rgb(184, 184, 184);
}

.on-hover-shadow:hover {
  background-color: rgb(244, 244, 244);
}

.on-hover-shadow:active {
  transform: scale(0.994) translateX(0.5px);
}

.font-light{
  color: #585555;
}

.fw-500{
  font-weight: 500;
}

.bottom-banner-outside-console{
  position: fixed;
  width: 100vw;
  padding: 0 5vw;
  background-color: #d7edff;
  height: 80px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.clickable{
  border: none;
}

.clickable:hover{
  cursor: pointer;
}

.card-item {
  max-width: 330px;
  aspect-ratio: 1.7;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 1100px) {
  .card-item{
    max-width: 270px;
  }
}

.card-item.-active .card-item__side.-front {
  transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
}
.card-item.-active .card-item__side.-back {
  transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
}
.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}

.card-item__focus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(8, 20, 47);
  height: 100%;
  border-radius: 5px;
  filter: blur(25px);
  opacity: 0.5;
}

.card-item__focus.-active {
  opacity: 1;
}
.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 16px 0 rgba(14, 42, 84, 0.55);
  transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.42, 0, 0, 1.05);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
}
.card-item__side.-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
  z-index: 2;
  padding: 0;
  height: 100%;
}
.card-item__side.-back .card-item__cover {
  transform: rotateY(-180deg);
}
.card-item__bg {
  max-width: 100%;
  display: block;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-item__cover {
  height: 100%;
  background-color: #1c1d27;
  position: absolute;
  height: 100%;
  background-color: #1c1d27;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  z-index: -1;
}
.card-item__cover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 2, 29, 0.45);
}
.card-item__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
  .card-item__top {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__top {
    margin-bottom: 10px;
  }
}
.card-item__chip {
  width: 40px;
}
@media screen and (max-width: 480px) {
  .card-item__chip {
    width: 50px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__chip {
    width: 40px;
  }
}
.card-item__type {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__type {
    height: 40px;
    max-width: 90px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__type {
    height: 30px;
  }
}
.card-item__typeImg {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  object-position: top right;
}
.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  font-weight: 500;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__info {
    padding: 10px;
  }
}
.card-item__holder {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}
@media screen and (max-width: 480px) {
  .card-item__holder {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.card-item__wrapper {
  font-family: "Source Code Pro", monospace;
  padding: 15px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
  user-select: none;
}
@media screen and (max-width: 480px) {
  .card-item__wrapper {
    padding: 20px 10px;
  }
}
.card-item__name {
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .card-item__name {
    font-size: 16px;
  }
}
.card-item__nameItem {
  display: inline-block;
  min-width: 8px;
  position: relative;
}
.card-item__number {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 22px;
  display: inline-block;
  padding: 10px 0px;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .card-item__number {
    font-size: 16px;
    padding: 10px 0px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__number {
    font-size: 19px;
    padding: 10px 10px;
  }
}
.card-item__numberItem {
  width: 13px;
  display: inline-block;
}
.card-item__numberItem.-active {
  width: 30px;
}
@media screen and (max-width: 480px) {
  .card-item__numberItem {
    width: 13px;
  }
  .card-item__numberItem.-active {
    width: 16px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__numberItem {
    width: 12px;
  }
  .card-item__numberItem.-active {
    width: 8px;
  }
}
.card-item__content {
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.card-item__date {
  flex-wrap: wrap;
  font-size: 14px;
  margin-left: auto;
  padding: 10px;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}

.ta-end{
  text-align: end;
}

@media screen and (max-width: 480px) {
  .card-item__date {
    font-size: 16px;
  }
}
.card-item__dateItem {
  position: relative;
  font-size: 14px;
}
.card-item__dateItem span {
  width: 22px;
  display: inline-block;
}
.card-item__dateTitle {
  opacity: 0.7;
  font-size: 13px;
  padding-bottom: 6px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__dateTitle {
    font-size: 12px;
    padding-bottom: 5px;
  }
}
.card-item__band {
  background: rgba(0, 0, 19, 0.8);
  width: 100%;
  height: 50px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .card-item__band {
    margin-top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__band {
    height: 40px;
    margin-top: 10px;
  }
}
.card-item__cvv {
  text-align: right;
  position: relative;
  z-index: 2;
  padding: 15px;
}
.card-item__cvv .card-item__type {
  opacity: 0.7;
}
@media screen and (max-width: 360px) {
  .card-item__cvv {
    padding: 10px 15px;
  }
}
.card-item__cvvTitle {
  padding-right: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.card-item__cvvBand {
  height: 45px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: #1a3b5d;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
}
@media screen and (max-width: 480px) {
  .card-item__cvvBand {
    height: 40px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__cvvBand {
    margin-bottom: 15px;
  }
}
.card-item__cvvBand span {
  display: inline-block;
}

.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: nne;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  transform: translateX(0px) rotate(0deg);
  position: relative;
  opacity: 100;
}

.slide-fade-right-exit {
  transform: translateX(0px) rotate(0deg);
  pointer-events: none;
}

.slide-fade-right-exit-active {
  opacity: 0;
  transition: all 0.25s ease-in-out;
  transform: translateX(30px) rotate(-80deg);
  position: relative;
}

.slide-fade-up-enter {
  transform: translateY(15px);
  pointer-events: none;
  opacity: 0;
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  transform: translateY(0px);
  position: relative;
  opacity: 1;
}

.slide-fade-up-enter-done {
  transform: translateY(0px);
  opacity: 1;
}

.slide-fade-up-exit {
  transform: translateY(0px);
  pointer-events: none;
  opacity: 1;
}

.slide-fade-up-exit-active {
  transition: all 250ms ease-in-out;
  transform: translateY(-15px);
  position: relative;
  opacity: 0;
}

.slide-fade-up-exit-done {
  opacity: 0;
}

.zoom-in-out-enter {
  transform: translateY(6px) scale(3.5);
  transition-delay: 0.1s;
  filter: blur(0.4px);
  opacity: 0;
}

.zoom-in-out-enter-active {
  transition: all 250ms ease-in-out;
  transform: translateY(0px) scale(1);
  filter: blur(0px);
  opacity: 1;
}

.zoom-in-out-enter-done {
  opacity: 1;
}

.zoom-in-out-exit {
  opacity: 1;
}

.zoom-in-out-exit-active {
  transition: all 150ms ease-in-out;
  transition-delay: 0.1s;
  transform: translateY(4px) scale(2.3);
  filter: blur(0.4px);
  position: relative;
}

.unblurred{
  filter: blur(0px)!important;
}

.last-4 {
  font-size: 22px;
}

@media screen and (max-width: 1600px) {
  .last-4 {
    font-size: 22px;
  } 
}

.asterix-font-size{
  font-size: 20px;
}

@media screen and (max-width: 1100px) {
  .last-4 {
    font-size: 20px;
  } 
  
  .asterix-font-size{
    font-size: 16px;
  }
}

.payment-box-wrapper {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(238, 238, 238);
  border-radius: 4px;
}

.card-details{
  border: 1px solid rgb(198, 198, 198);
  background-color: #4862e2;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.clickable:hover{
  cursor: pointer;
}

.light-color-asterix{
  color: #4a4a4ad4
}

.height-33-px{
  height: 33px!important;
}

.add-new-card-top{
  background-color: #2c6ad5;
  color: white;
}

.add-new-card-bottom{
  color: white;
}

/* .cards-grid-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  gap: 30px;
  align-content: flex-start;
} */

/* .cards-grid-wrapper {
  display: grid;
  
  width: 100%;
  justify-content: flex-start;
  gap: 30px;
  align-content: flex-start;
} */

.cards-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 items for screens > 1400px */
  gap: 20px;
}

@media screen and (max-width: 1670px) {
  .cards-grid-wrapper {
    grid-template-columns: repeat(3, 1fr); /* 2 items for 900px-1400px */
  }
}

@media screen and (max-width: 1370px) {
  .cards-grid-wrapper {
    grid-template-columns: repeat(2, 1fr); /* 1 item for screens < 900px */
  }
}

@media screen and (max-width: 876px) and (min-width: 768px) {
  .cards-grid-wrapper {
      grid-template-columns: repeat(2, 1fr); /* 1 item for screens < 900px */
    }
}

@media screen and (max-width: 1100px) and (min-width: 876px){
  .cards-grid-wrapper {
      width: 540px;
  }
}

@media screen and (max-width: 600px){
  .cards-grid-wrapper {
    grid-template-columns: repeat(1, 1fr); /* 1 item for screens < 900px */
  }
}

.mantine-Modal-body .EdS{
  color: #cfcfcf!important;
}

.machine-loading-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100px;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .machine-loading-wrapper {
    width: 50%;
  }
}

#profile-email-id, #profile-phone-id {
  color: black;
  font-weight: 500;
}

#curr-password-id, #new-password-id {
  color: black;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif!important;
}

button[name=profile-password-submit-btn]{
  background-color: #ffffff;
}

button[name=profile-password-submit-btn] > div{
  background-color: #006061;
}

button[name=profile-password-submit-btn] > div > div{
  color: #ffffff!important;
  font-weight: 500!important;
  font-size: 15px!important;
}

button[name=profile-password-submit-btn] > .a_A:hover{
  background-color: #357059!important;
}

button[name=profile-password-reset-btn] > div > div {
  font-weight: 500!important;
  font-size: 15px!important;
}

.IZT{
  user-select: none;
}

.width-clamp{
  width: 490px;
}

@media screen and (max-width: 948px) {
  .width-clamp{
      width: 100%;
  }
}

button[name=refer-btn]{
  border-radius: 4px;
}

button[name=refer-btn] > div{
  border-radius: 4px;
}

button[name=refer-btn] > div{
  background-color: #006061;
  height: 42px;
}

button[name=refer-btn] > div > div{
  color: white!important;
  font-weight: 600!important;
  font-size: 16px!important;
}

button[name=refer-btn] > .a_A:hover{
  background-color: #357059!important;
}

.col-below-768{
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .col-below-768{
      flex-direction: column;
  }   

  .flex-end-below-768{
      width: 100%;
      justify-content: flex-end;
  }
}

.section-label{
  width: 10%;
}

.right-subcomponent{
  width:30%;
}

.helper-text-subcomponent{
  width: 25%;
}

.half-page-sections{
  width: 60%!important;
}

.half-page-sections .O2T{
  color: rgb(19, 19, 19);
  /* font-size: 14px; */
}

.gestalt-form-wrapper .O2T{
  font-size: 14px;
  font-weight: 400;
}


@media screen and (max-width: 1530px) {
  .section-label{
      width: 10%;
  }

  .right-subcomponent{
      width: 40%;
  }

  .helper-text-subcomponent{
      width: 25%;
  }
}

@media screen and (min-width: 1570px) {
  .half-page-sections{
      width: 50%!important;
  }    
}

@media screen and (max-width: 1260px) {
  .half-page-sections{
      width: 100%!important;
  }    
}

@media screen and (max-width: 1280px) {
  .section-label{
      width: 20%;
  }

  .right-subcomponent{
      width: 80%;
  }

  .helper-text-subcomponent{
      width: 0%;
      display: none;
      visibility: hidden;
  }

  .child-that-disappears{
      display: none!important;
      width: 0%!important;
  }

  .child-that-disappears-child{
      display: none!important;
      width: 0%!important;
  }

  .helper-text-subcomponent{
      position: absolute!important;
  }

  /* .half-page-sections{
      width: 100%!important;
  } */
}

.child-that-disappears{
  display: none!important;
}

.parent-that-hovers:hover .child-that-disappears{
  display: block!important;
}

.light-dotted-border{
  border: 0.9px dotted #0e0e0e;
}

.light-dotted-border:focus{
  border: none;
}

.qw{
  color: 2px solid #d5d5d5
}

.support-form-wrapper{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.form-gap{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.position-relative{
  position: relative;
}

@media screen and (min-width: 1570px) {
  .support-form-wrapper{
      width: 45%;
  }    
}

@media screen and (max-width: 1200px) {
  .support-form-wrapper{
      width: 100%;
  }    
}

#email-id, #subject-id, #description-id, #id-id{
  border-radius: 8px;
}

button[name=topic-dropdown-id] > div{
  border-radius: 8px;
  border: 2px solid var(--color-border-container);
  height: 36px!important;
  transform: scaleY(0.92)!important;
}

button[name=topic-dropdown-id] > div .sAJ {
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font-family-default-latin);
}

button[name=topic-dropdown-id] > div:focus{
  box-shadow: none!important;
}

button[name=topic-dropdown-id]{
  height: 36px!important;
  box-shadow: none!important;
}

.Dl7.SpV.m8d.ho-.ygj._O1.XbT.DHH.kFh{
  width: 100%;
}

#dropdown-wrapper button {
  border-radius: 8px!important;
  box-shadow: none!important;
}

#dropdown-wrapper .O2T{
  font-size: 14px;
  font-weight: 500!important;
}

#dropdown-wrapper .BsF {
  border-radius: 8px!important;
  box-shadow: none!important;
  background-color: #f0f0f0;
}

#dropdown-wrapper button div {
  /* background-color: white; */
  border-radius: 4px!important;
  color: black;
  justify-content: flex-start;
  font-weight: 500!important;
}

#dropdown-wrapper {
  padding: 4px 4px 4px 4px;
  border-radius: 4px;
}

.upload-file{
  border: 2px solid var(--color-border-container)!important;
}

.file-input-wrapper > div > button {
  border: 2px solid var(--color-border-container)!important;
  height: 44px;
  border-radius: 8px;
}

.file-input-wrapper > div > button:focus {
  box-shadow: 0 0 0 4px rgb(0 132 255/.5);
}

#description-id-helperText{
  margin-top: 4px;
}

#btn-groups-id > button{
  border-radius: 4px!important;
}

#btn-groups-id > button > div{
  border-radius: 4px!important;
}

#submit-btn{
  background-color: #f0f0f0;
}

button[name=support-submit-btn]{
  background-color: #ffffff;
}

button[name=support-submit-btn] > div{
  background-color: #006061;
}

button[name=support-submit-btn] > div > div{
  color: #ffffff!important;
  font-weight: 600!important;
  font-size: 16px!important;
}

button[name=support-submit-btn] > .a_A:hover{
  background-color: #357059!important;
}

button[name=support-reset-btn] > div > div {
  font-weight: 600!important;
  font-size: 18px!important;
}

#support-form-wrapper .mAg {
  color: black!important;
}

.ho- {
  border-radius: 4px !important;
}

.on-hover-slightly-darker:hover {
  background-color: rgb(244, 244, 244);
}

.on-hover-slightly-darker:active {
  transform: translateX(0.5px);
}

.empty-basket-size{
  width: 25%;
}

@media screen and (max-width: 1200px) {
  .empty-basket-size{
    width: 40%;
  }
}

.stepper-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fefefe;
  margin-top: -30px;
  overflow: hidden;
}

.phone-num-input {
  width: 100%;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 2px 2px 1px black;
}

.submit-btn {
  background-color: black !important;
}

.submit-btn:hover {
  background-color: white !important;
  color: black;
  border: 2px solid black;
}

#phone-number-input::placeholder {
  color: rgb(195, 195, 195);
}

.otp-verification-wrapper {
  max-width: 540px;
}

.otp-button-wrapper{
  width: 290px;
}

@media screen and (max-width: 768px) {
  .otp-button-wrapper{
    width: 210px;
  }  
}

.otp-input {
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 0.5px 1px 1px black;
  width: 65px;
  aspect-ratio: 1;
  font-size: 40px;
  display: flex;
  text-align: center;
}

.otp-input-border{
  border: 1px solid rgb(124, 124, 124);
}

@media screen and (max-width: 768px) {
  .otp-input {
    width: 45px;
    font-size: 20px;
  }
}

.secondary-button {
  border: 2px solid black !important;
}

.secondary-button:hover {
  color: white;
  background-color: rgb(0, 0, 0);
}
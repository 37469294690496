.footer {
    margin-top: 30px;
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  }
  
  .logo {
    max-width: 200px;
  
    @media (max-width: var(--mantine-breakpoint-sm)) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  .description {
    margin-top: 5px;
  }

  @media screen and (max-width: 768px) {
    .description{
      display: none;
    }
  }
  
  .inner {
    display: flex;
    justify-content: space-between;
    padding-top: calc(var(--mantine-spacing-sm) * 2);

    @media (max-width: var(--mantine-breakpoint-sm)) {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .groups {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    .groups{
      display: none;
    }
  }
  
  .wrapper {
    width: 160px;
  }
  
  .link {
    display: block;
    font-size: var(--mantine-font-size-sm)!important;
    padding-top: 3px;
    padding-bottom: 3px;
    text-decoration: underline!important;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .title {
    font-size: var(--mantine-font-size-lg)!important;
    font-weight: 700!important;
    margin-bottom: calc(var(--mantine-spacing-xs) / 2)!important;
  }
  
  .afterFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--mantine-spacing-xl);
    padding-top: var(--mantine-spacing-xl);
    padding-bottom: var(--mantine-spacing-xl);
  }

  @media (max-width: 768px) {
    .afterFooter {
      flex-direction: column;
      margin-top: var(--mantine-spacing-xs);
    }
  }
  
  .social {
    @media (max-width: var(--mantine-breakpoint-sm)) {
      margin-top: var(--mantine-spacing-xs);
    }
  }
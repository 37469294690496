.sign-up-page {
  background-color: rgb(0, 0, 0);
  scroll-snap-align: start;
  height: 100dvh;
  width: 100dvw;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10vw;
  padding-right: 10vw;
  position: relative;
  /* padding-bottom: 5vh; */
  scroll-snap-stop: always!important;
  gap: 20px;
  background-image: url("https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/04b1241f-0023-4995-b524-e7ce1d28db00/publiclg");
  background-position: bottom;
}

@media screen and (max-width: 768px) {
  .sign-up-page {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.data-point-wrapper {
  background-color: white;
  padding: 15px;
  border-radius: 20px;
  width: 195px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 1);
}

.data-point-tile {
  display: flex;
  gap: 20px;
}

.auth-wrapper {
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex: 1;
}

@media screen and (max-width: 1200px) {
  .data-point-tile {
    flex-direction: column;
  }

  .auth-wrapper {
    flex: 2;
  }
}

.bottom-nav-link {
  color: rgb(199, 199, 199);
  font-size: 12px;
}

.bottom-nav-link:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
}

.blur-heavy{
  filter: blur(2px);
}

.absolute-and-centered{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-col{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

button[name=homepage-auth-sign-in]{
  width: 160px!important;
}


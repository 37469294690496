/* arrow start */
.top-arrow-first-page {
  position: absolute;
  top: 50px;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-arrow-first-page:hover {
  cursor: pointer;
}

@keyframes topJumpAnimation {
  0% {
    top: 25px;
  }

  50% {
    top: 45px;
  }

  100% {
    top: 25px;
  }
}

.top-arrow-first-page {
  animation: topJumpAnimation 2.5s infinite;
}

.auth-single-page {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10dvh;
  padding-left: 3dvw;
  padding-right: 3dvw;
  background-size: cover;
  background-color: black;
}

.auth-single-page-navbar{
  position: fixed;
  top: 0;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  color: white;
  display: flex;
  height: 55px;
  z-index: 5;
  width: 100%;
  gap: 60px;
  align-items: center;
  justify-content: space-between;
}

.sign-up-page .auth-form-outside-modal-wrapper{
  box-shadow: -5px -5px 10px 0px rgba(0, 0, 0, 1);
}

@media screen and (max-width: 768px) {
  .auth-single-page-navbar{
    padding-left: 3dvw;
    padding-right: 3dvw;
  }
}

@media screen and (max-width: 768px) {
  .auth-single-page {
    align-items: center;
    padding-top: 0;
  }
}

.navbar {
  width: 100dvw;
  height: var(--top-navbar-height);
  position: fixed;
  top: 0;
  padding: var(--mantine-spacing-md);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: black;
}

.navbarMain {
  flex: 1;
  margin-top: rem(50px);
}

.link {
  width: rem(50px);
  height: rem(50px);
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mantine-color-white);

  &:hover {
    background-color: var(rgb(95, 1, 1));
  }

  &[data-active] {
    &,
    &:hover {
      box-shadow: var(--mantine-shadow-sm);
      background-color: var(--mantine-color-white);
      color: var(--mantine-color-blue-6);
    }
  }
}

.first-page-container{
    height: 88dvh;
    width: 100dvw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.recruitingQualities{
    width: 48px;
    height: 48px;
}

.entire-page-wrapper{
    padding-top: 56px;
    -webkit-transition: background-color 0.4s ease-in-out, color 0.2s ease-in-out;
    -moz-transition: background-color 0.4s ease-in-out, color 0.2s ease-in-out;
    -o-transition: background-color 0.4s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.4s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.4s ease-in-out, color 0.2s ease-in-out;
}

.entire-page-wrapper *{
    font-family: 'Urbanist', sans-serif;;
}

.about-header{
    font-size: 78px!important;
    font-weight: 500;
}

.about-text{
    max-width: 744px;
    text-align: center;
}

.smaller-text{
    font-size: 24px;
    line-height: 1.6;
}

.abt-second-container{
    background: url("https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/92d41b16-b583-4400-c4a2-0f77ec1d8900/homepage");
    height: 100dvh;
    width: 100dvw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.abt-third-container{
    width: 100dvw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding-bottom: 100px;
    gap: 130px;
    padding-right: 3vw;
    padding-left: 3vw;
}

.abt-forth-container{
    width: 100dvw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding-bottom: 100px;
}

.abt-fourth-container{
    width: 100dvw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.team-container-wrapper{
    background-color: transparent!important;
}

.description-wrapper{
    max-width: 200px;
}

@media screen and (max-width: 1100px) {
    .abt-second-container{
        height: 60dvh;
    }
    
    .about-header{
        font-size: 60px!important;
    }
    
    .about-text{
        max-width: 700px;
        text-align: center;
    }
    
    .smaller-text{
        font-size: 18px;
    }

    .about-text{
        max-width: 500px;
    }

    .abt-third-container{
        gap: 100px;
    }
}

@media screen and (max-width: 768px) {
    .about-header{
        font-size: 48px!important;
    }

    .about-text{
        max-width: 80vw!important;
    }
}


.max-width-90vw{
    max-width: 90vw!important;
}
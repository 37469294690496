/* arrow start */
.down-arrow-first-page {
  position: absolute;
  bottom: 50px;
  width: 40px;
  height: 40px;
  background-color: #ffe4c1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
}

.down-arrow-first-page:hover {
  cursor: pointer;
}

@keyframes jumpInfiniteFirstPage {
  0% {
    bottom: 85px;
  }

  50% {
    bottom: 45px;
  }

  100% {
    bottom: 85px;
  }
}

.down-arrow-first-page {
  animation: jumpInfiniteFirstPage 2.5s infinite;
}

@media screen and (max-width: 768px) {
  .down-arrow-first-page {
    display: none;
  }
}

.section-label{
    width: 10%;
}

.right-subcomponent{
    width:30%;
}

.helper-text-subcomponent{
    width: 25%;
}

.half-page-sections{
    width: 60%!important;
}

.half-page-sections .O2T{
    color: rgb(19, 19, 19);
    /* font-size: 14px; */
}

.gestalt-form-wrapper .O2T{
    font-size: 14px;
    font-weight: 400;
}

.form-outside-console-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background-color: white;
    border-radius: 20px;
}


@media screen and (max-width: 1530px) {
    .section-label{
        width: 10%;
    }

    .right-subcomponent{
        width: 40%;
    }

    .helper-text-subcomponent{
        width: 25%;
    }
}

@media screen and (min-width: 1570px) {
    .half-page-sections{
        width: 50%!important;
    }    
}

@media screen and (max-width: 1260px) {
    .half-page-sections{
        width: 100%!important;
    }    
}

@media screen and (max-width: 1280px) {
    .section-label{
        width: 20%;
    }

    .right-subcomponent{
        width: 80%;
    }

    .helper-text-subcomponent{
        width: 0%;
        display: none;
        visibility: hidden;
    }

    .child-that-disappears{
        display: none!important;
        width: 0%!important;
    }

    .child-that-disappears-child{
        display: none!important;
        width: 0%!important;
    }

    .helper-text-subcomponent{
        position: absolute!important;
    }

    /* .half-page-sections{
        width: 100%!important;
    } */
}

.child-that-disappears{
    display: none!important;
}

.parent-that-hovers:hover .child-that-disappears{
    display: block!important;
}

.light-dotted-border{
    border: 0.9px dotted #0e0e0e;
}

.light-dotted-border:focus{
    border: none;
}

.qw{
    color: 2px solid #d5d5d5
}

.form-gap{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.position-relative{
    position: relative;
}

#email-id, #subject-id, #description-id, #id-id{
    border-radius: 8px;
}

button[name=topic-dropdown-id] > div{
    border-radius: 8px;
    border: 2px solid var(--color-border-container);
    height: 36px!important;
    transform: scaleY(0.92)!important;
}

button[name=topic-dropdown-id] > div .sAJ {
    font-size: 15px;
    font-weight: 400;
    font-family: var(--font-family-default-latin);
}

button[name=topic-dropdown-id] > div:focus{
    box-shadow: none!important;
}

button[name=topic-dropdown-id]{
    height: 36px!important;
    box-shadow: none!important;
}

.Dl7.SpV.m8d.ho-.ygj._O1.XbT.DHH.kFh{
    width: 100%;
}

#dropdown-wrapper button {
    border-radius: 8px!important;
    box-shadow: none!important;
}

#dropdown-wrapper .O2T{
    font-size: 14px;
    font-weight: 500!important;
}

#dropdown-wrapper .BsF {
    border-radius: 8px!important;
    box-shadow: none!important;
    background-color: #f0f0f0;
}

#dropdown-wrapper button div {
    /* background-color: white; */
    border-radius: 4px!important;
    color: black;
    justify-content: flex-start;
    font-weight: 500!important;
}

#dropdown-wrapper {
    padding: 4px 4px 4px 4px;
    border-radius: 4px;
}

.upload-file{
    border: 2px solid var(--color-border-container)!important;
}

.file-input-wrapper > div > button {
    border: 2px solid var(--color-border-container)!important;
    height: 44px;
    border-radius: 8px;
}

.file-input-wrapper > div > button:focus {
    box-shadow: 0 0 0 4px rgb(0 132 255/.5);
}

#description-id-helperText{
    margin-top: 4px;
}

#btn-groups-id > button{
    border-radius: 4px!important;
}

#btn-groups-id > button > div{
    border-radius: 4px!important;
}

#submit-btn{
    background-color: #f0f0f0;
}

button[name=support-submit-btn]{
    background-color: #ffffff;
}

button[name=support-submit-btn] > div{
    background-color: #006061;
}

button[name=support-submit-btn] > div > div{
    color: #ffffff!important;
    font-weight: 600!important;
    font-size: 16px!important;
}

button[name=support-submit-btn] > .a_A:hover{
    background-color: #357059!important;
}

button[name=support-reset-btn] > div > div {
    font-weight: 600!important;
    font-size: 18px!important;
}

#support-form-wrapper .mAg {
    color: black!important;
}

.Support_scrollareaViewport__-3lW4 > div{
    display: flex!important;
    flex-direction: column;
    gap: 48px;
}

.background-gpu{
    background-image: url("https://imagedelivery.net/4TuMP_Ovs4ZBNMvJkMr1uA/04b1241f-0023-4995-b524-e7ce1d28db00/publiclg")
}

.border-radius-bt-16{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.bubble-wrap {
  overflow: hidden;
}

.bubbles {
  position: relative;
  height: 60dvh;
  width: 50dvw;
}

@media screen and (max-width: 948px) {
  .bubbles {
    height: 50dvh;
    width: 80dvw;
  }
}

.bubble-1 {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/mx-net.png);
  background-size: 110px 110px;
  /* top: 200px; */
}

.bubble-2 {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/jupyter-96.png);
  background-size: 110px 110px;
  /* top: 400px; */
}

.bubble-3 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/logos-of-companies/keras-logo.png);
  background-size: 140px 140px;
  /* top: 600px; */
}

.bubble-4 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/logos-of-companies/numpy-logo.svg);
  background-size: 122px 122px;
  /* top: 150px;
    left: 200px */
}

.bubble-5 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/logos-of-companies/pandas-logo.svg);
  background-size: 122px 122px;
  /* top: 350px;
    left: 200px */
}

.bubble-6 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/logo.svg);
  background-size: 122px 122px;
  /* top: 0px;
    left: 400px */
}

.bubble-7 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/logos-of-companies/tensorflow-only-logo.png);
  background-size: 122px 122px;
  /* top: 250px;
    left: 400px */
}

.bubble-8 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/windows-96.png);
  background-size: 122px 122px;
  /* top: 0px;
    left: 0px */
}

.bubble-9 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/logos-of-companies/xgboost-logo.png);
  background-size: 122px 122px;
  /* top: 600px;
    left: 400px */
}

.bubble-10 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/logos-of-companies/open-ai-logo.png);
  background-size: 122px 122px;
  /* top: -50px;
    left: 200px */
}

.bubble-11 {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/ubuntu-96.png);
  background-size: 120px 120px;
  background-position: right top;
  /* top: 200px;
    left: 400px */
}

.bubble-12 {
  position: absolute;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.1);
  background-image: url(https://college-poster.s3.us-east-2.amazonaws.com/images-for-libvirt-frontend/mnist.png);
  background-size: 124px 124px;
  /* top: 200px;
    left: 400px */
}

.root {
  background-color: var(
    --notification-error-color,
    var(--mantine-primary-color-filled)
  );

  &::before {
    background-color: var(--mantine-color-white);
  }
  border-radius: 15px;
}

.description,
.title {
  color: var(--mantine-color-white);
}

.closeButton {
  color: var(--mantine-color-white);
}

.closeButton:hover {
  /* color: rgba(0, 0, 0, 0.1); */
  color: var(--mantine-color-black);
}

.header {
  background-color: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
}

.inner {
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
  align-items: center;
}

.link {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  font-size: var(--mantine-font-size-md);
  font-weight: 600;
  font-size: 16px;
}

.link:hover {
  cursor: pointer;
}

.linkLabel {
  margin-right: 5px;
}

@media screen and (min-width: 768px) {
  .hiddenDesktop {
    display: none;
  }
}
